var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"pa-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-10",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithIndex,"loading":_vm.loading,"loading-text":"Please wait..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"0","height":"100px"}},[_c('div',[_c('p',{staticClass:"mb-0 text-h4 font-weight-bold"},[_vm._v(" All Expenses ")])]),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date_month,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date_month=$event},"update:return-value":function($event){_vm.date_month=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"Sort By Month","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.date_month),callback:function ($$v) {_vm.date_month=$$v},expression:"date_month"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"type":"month","scrollable":"","prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next"},on:{"input":_vm.onMonthOrDateChange},model:{value:(_vm.date_month),callback:function ($$v) {_vm.date_month=$$v},expression:"date_month"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"Sort By Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next"},on:{"input":function($event){return _vm.onMonthOrDateChange('date')}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)]},proxy:true},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toDateString())+" ")]}},{key:"item.statusSlot",fn:function(ref){
var item = ref.item;
return [(item.is_delete)?_c('div',[_vm._v("Inactive")]):_c('div',[_vm._v("Active")])]}},{key:"item.note_details",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-note")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline deep-purple darken-1 white--text py-4"},[_vm._v(" Note ")]),_c('v-card-text',[(item.note)?_c('div',[_vm._v(_vm._s(item.note))]):_c('div',[_vm._v("No note provided.")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.is_delete)?_c('v-btn',{attrs:{"icon":"","color":"red","dark":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }