<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="pa-10">
            <v-col cols="12">
              <v-card class="mb-10" elevation="0">
                <v-data-table
                  :headers="headers"
                  :items="itemsWithIndex"
                  class="elevation-1"
                  :loading="loading"
                  loading-text="Please wait..."
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="0"
                      style="background: #f5f5f5"
                      height="100px"
                    >
                      <div>
                        <p class="mb-0 text-h4 font-weight-bold">
                          All Expenses
                        </p>
                      </div>
                      <v-spacer></v-spacer>

                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date_month"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date_month"
                            label="Sort By Month"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            class="pt-5"
                            style="width: 50px"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date_month"
                          @input="onMonthOrDateChange"
                          type="month"
                          scrollable
                          class="mt-0"
                          prev-icon="mdi-skip-previous"
                          next-icon="mdi-skip-next"
                        >
                        </v-date-picker>
                      </v-menu>

                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Sort By Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            class="pt-5"
                            style="width: 50px"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="onMonthOrDateChange('date')"
                          prev-icon="mdi-skip-previous"
                          next-icon="mdi-skip-next"
                          class="mt-0"
                        ></v-date-picker>
                      </v-menu>
                    </v-toolbar>
                  </template>

                  <!-- <template v-slot:item.rank="{ index }">
                    {{ index + 1 }}
                  </template> -->
                  <template v-slot:item.time="{ item }">
                    {{ new Date(item.created_at).toDateString() }}
                  </template>
                  <template v-slot:item.statusSlot="{ item }">
                    <div v-if="item.is_delete">Inactive</div>
                    <div v-else>Active</div>
                  </template>
                  <template v-slot:item.note_details="{ item }">
                    <v-dialog v-model="dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red lighten-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                        >
                         <v-icon>mdi-note</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="headline deep-purple darken-1 white--text py-4">
                          Note
                        </v-card-title>

                        <v-card-text>
                          <div v-if="item.note">{{item.note}}</div>
                           <div v-else>No note provided.</div>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="dialog = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      icon
                      color="red"
                      dark
                      @click="onDelete(item)"
                      v-if="!item.is_delete"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog : false,
      loading: true,
      user: {},
      date_month: new Date().toISOString().substr(0, 7),
      menu: false,

      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "S/N", value: "index", sortable: false },
        {
          text: "User Name",
          value: "creator_email",
          sortable: false,
          align: "start",
        },
        {
          text: "Expense",
          value: "expense_name",
          sortable: false,
          align: "start",
        },
        {
          text: "Source",
          value: "creator_role",
          sortable: false,
          align: "start",
        },
        { text: "Amount", value: "expense_amount", align: "center" },
        { text: "Time", value: "time", sortable: false, align: "center" },
        { text: "Status", value: "statusSlot", align: "" },
        { text: "Note", value: "note_details", align: "" },
        { text: "Action", value: "action", sortable: false, align: "center" },
      ],
      expenses: [],
    };
  },

  computed: {
    itemsWithIndex() {
      return this.expenses.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },

  methods: {
    initialize() {
      // this.user = JSON.parse(localStorage.getItem("user"));
      // if (this.user.user_id) {
      //   axios
      //     .get(`/manager/show_user_expense/${this.user.user_id}`)
      //     .then((response) => {
      //       if (response.data.success) {
      //         this.expenses = response.data.data
      //           .filter((element) => !element.is_delete)
      //           .reverse();
      //         this.loading = false;
      //       } else {
      //         this.loading = false;
      //       }
      //     })
      //     .catch((err) => {
      //       this.loading = false;
      //     });
      // }

      this.onChangeCall(this.date);
    },

    onMonthOrDateChange(type = "month") {
      if (type == "date") {
        this.menu2 = false;
        this.onChangeCall(this.date);
      } else {
        this.$refs.menu.save(this.date_month);
        this.onChangeCall(this.date_month);
      }
    },

    onChangeCall(payload) {
      this.loading = true;

      axios
        .get(`/manager/get_details_info/${payload}`)
        .then((response) => {
          if (response.data.success) {
            this.expenses = response.data.data.reverse();
            this.loading = false;
          } else {
            this.expenses = [];
            this.loading = false;
          }
        })
        .catch((err) => {
          this.expenses = [];
          this.loading = false;
        });
    },

    // Make inactive
    onDelete(item) {
      // Find the element
      let element = this.expenses.find((expense) => expense.id == item.id);

      axios.post(`/manager/delete_expense/${item.id}`).then((response) => {
        if (response.data.success) {
          element.is_delete = true;
        }
      });
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>